/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';
import { i18n as _i18n } from '@sv/util';

const i18cache = {};
const i18n = function (selector, args) {
  let translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = _i18n.getText(
    'portlet.sharepoint.sharepointfile',
    selector,
    args
  );

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
};
const i18nCommon = function (selector, args) {
  return _i18n.getText('common', selector, args);
};

$('.sv-sharepointfile-portlet, .sv-sharepointsearchfilelist-portlet').each(
  function () {
    var $portlet = $(this),
      $modal = $portlet.find('.sv-sharepoint-login-modal');

    import(/* webpackChunkName: "svdialog" */ './plugins').then(() => {
      $modal.svDialog({
        title: i18n('connectToSharePoint'),
        open: false,
        modal: true,
        buttons: [
          {
            text: i18n('loginButton'),
            type: 'primary',
            callback: function (event) {
              event.preventDefault();
              $modal.find('form').trigger('submit');
            },
          },
          {
            text: i18nCommon('cancel'),
            type: 'link',
            callback: function () {
              $modal.svDialog('close');
            },
          },
        ],
      });

      $portlet.find('.sv-sharepoint-modal-button').on('click', function () {
        $modal.svDialog('open');
      });
    });
  }
);

/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author robin
 */

import $ from '@sv/jquery';

$('form[data-sv-single-click]').one('submit', function () {
  $(this).find('input[type=submit]').prop('disabled', true);
});

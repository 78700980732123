import _Backbone from 'backbone';
import { ErrorUtil as errorUtil } from '@sv/util';
import $ from '@sv/jquery';
import _ from '@sv/underscore';

var SVBackbone = (window.SVBackbone = _Backbone.noConflict());
SVBackbone.$ = $;
export default SVBackbone;

var modelFetch = SVBackbone.Model.prototype.fetch;
var modelSave = SVBackbone.Model.prototype.save;
var modelDestroy = SVBackbone.Model.prototype.destroy;
var collectionFetch = SVBackbone.Collection.prototype.fetch;

function defaultErrorHandler(model, response, options) {
  errorUtil.handleBackboneError(model, response, options);
}

/**
 * Overrides backbone Model#fetch, #save, #destroy methods adding default error handling
 */
SVBackbone.Model.prototype.fetch = function (options) {
  options = options || {};
  options.error = options.error || defaultErrorHandler;
  return modelFetch.apply(this, [options]);
};

SVBackbone.Model.prototype.save = function (key, val, options) {
  if (key == null || typeof key === 'object') {
    // val will be the options - see backbone.js
    val = val || {};
    val.error = val.error || defaultErrorHandler;
  } else {
    options = options || {};
    options.error = options.error || defaultErrorHandler;
  }
  return modelSave.apply(this, [key, val, options]);
};

SVBackbone.Model.prototype.destroy = function (options) {
  options = options || {};
  options.error = options.error || defaultErrorHandler;
  return modelDestroy.apply(this, [options]);
};

/**
 * Overrides backbone Collection#fetch methods adding default error handling
 */
SVBackbone.Collection.prototype.fetch = function (options) {
  options = options || {};
  options.error = options.error || defaultErrorHandler;
  return collectionFetch.apply(this, [options]);
};

/**
 * Overrides backbone View#preinitialize method due to change in backbone 1.1.0:
 * Backbone Views no longer automatically attach options passed to the constructor as this.options
 */
SVBackbone.View.prototype.preinitialize = function (options) {
  if (this.options) {
    options = _.extend({}, _.result(this, 'options'), options);
  }
  this.options = options;
};

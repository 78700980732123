import $ from '@sv/jquery';

$('.sv-has-error').each(function () {
  const $this = $(this);
  const formElSelector = 'input, textarea, select';
  const $formEl = $this.is(formElSelector) ? $this : $this.find(formElSelector);
  $formEl.attr('aria-invalid', 'true');
  $this.on('change keyup', function () {
    $this.removeClass('sv-has-error');
    $formEl.removeAttr('aria-invalid');
    $this.off();
  });
});

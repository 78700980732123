import $ from '@sv/jquery';

const setParticipantsVisible = ($el, mandatory) => {
  const visibleRowCount = parseInt($el.val(), 10);
  const $rows = $el.closest('table').find('[data-sv-form-participants-row]');
  const renameAttribute = ($el, oldAttr, newAttr) => {
    $el.attr(newAttr, $el.attr(oldAttr));
    $el.removeAttr(oldAttr);
  };

  $rows.slice(0, visibleRowCount).show();
  $rows.slice(visibleRowCount).hide();

  if (mandatory) {
    $rows
      .slice(0, visibleRowCount)
      .find('input')
      .each((i, el) => {
        const $el = $(el);
        $el.prop('required', true);
        renameAttribute(
          $el,
          'data-sv-form-req-invalid-disabled',
          'data-sv-form-req-invalid'
        );
      });
    $rows
      .slice(visibleRowCount)
      .find('input')
      .each((i, el) => {
        const $el = $(el);
        $el.prop('required', false);
        renameAttribute(
          $el,
          'data-sv-form-req-invalid',
          'data-sv-form-req-invalid-disabled'
        );
      });
  }
};

$('[data-sv-form-participants]').each((i, el) => {
  const $el = $(el);
  const mandatory = $el.data('sv-form-participants');

  $el.on('change', () => {
    setParticipantsVisible($el, mandatory);
  });

  setParticipantsVisible($el, mandatory);
});

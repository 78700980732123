import $ from '@sv/jquery';
import sv from '@sv/core';

export const imgSlideshow = function (selector, options) {
  import(/* webpackChunkName: "slideshow-plugins" */ './plugins').then(() => {
    options = options || {};

    const $carousel = $(selector).find('.sv-carousel').first();
    if (options.autoplay) {
      $carousel.trigger('play.owl.autoplay', [options.autoplayTimeout]);
    } else {
      $carousel.trigger('stop.owl.autoplay');
    }
    $carousel.svCarousel(options);

    if (!$carousel.is(':visible') && 'IntersectionObserver' in window) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach(() => {
          if ($carousel.is(':visible')) {
            $carousel.svCarousel('refresh');
            observer.disconnect();
          }
        });
      }).observe($carousel[0]);
    }
  });
};

sv.imgSlideshow = imgSlideshow;

/**
 * Copyright (C) Sitevision AB 2002-2022, all rights reserved
 *
 * @author albin
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import './plugins';

// eslint-disable-next-line no-useless-escape
const EMAIL_REG_EXP = /^[A-Z0-9._%+\-]+@([A-Z0-9][.\-]?)+[A-Z0-9]+\.[A-Z]{2,6}$/i; // Reg ex copied from ValidationUtil.java

function ContactPortlet(options) {
  this.options = options;
  this.bindLinkListener();
}

ContactPortlet.prototype = {
  bindLinkListener: function () {
    var that = this;
    this.options.$portlet
      .find('.' + this.options.linkClass)
      .on('click', function () {
        that.openDialog.call(that, this);
        return false;
      });
  },

  getDialogContainer: function () {
    if (!this.$dialogContainer) {
      this.$dialogContainer = $('<div/>');
    }

    return this.$dialogContainer;
  },

  showMessageDialog: function (message) {
    if (!this.$subDialogContainer) {
      this.$subDialogContainer = $('<div/>')
        .html(message)
        .svDialog({
          open: true,
          title: this.options.sendMessageTitle,
          buttons: [
            {
              text: this.options.closeButtonText,
              type: 'primary',
              callback: $.proxy(function () {
                this.$subDialogContainer.svDialog('close');
              }, this),
            },
          ],
        });
    } else {
      this.$subDialogContainer.html(message).svDialog('open');
    }
  },

  validateForm: function () {
    var valid = true,
      $form = this.getDialogContainer().find('form'),
      $consentBox = $form.find('input[data-sv-form-consent-checkbox]');

    $.each(
      this.options.requiredFields,
      $.proxy(function (index, name) {
        var $field = $form.find('[name=' + name + ']');
        if (!this.validateField($field)) {
          valid = false;
        }
      }, this)
    );

    if (this.options.attachmentField) {
      var $field = $form.find('[name=attachment]');

      if (!this.checkAttachmentSize($field)) {
        valid = false;
      }
    }

    if (!this.validateConsent($consentBox)) {
      valid = false;
    }

    return valid;
  },

  validateField: function ($field) {
    var emailField = $field.is('[name=emailAddress]'),
      attachmentField = $field.is('[name=attachment]');
    if (!this.checkRequiredField($field)) {
      this.appendErrorMessage(
        $field,
        this.options.errorMessages[$field.attr('name')]
      );
      return false;
    } else if (emailField && !this.checkEmailAddress($field)) {
      this.appendErrorMessage($field, this.options.errorMessages.invalidEmail);
      return false;
    } else if (attachmentField && !this.checkAttachmentSize($field)) {
      this.appendErrorMessage(
        $field,
        this.options.errorMessages.fileSizeExceeded
      );
      return false;
    } else {
      this.removeValidationErrors($field);
      return true;
    }
  },

  validateConsent: function ($consentBox) {
    if ($consentBox.length && !$consentBox.is(':checked')) {
      this.appendConsentErrorMessage($consentBox);
      return false;
    } else {
      this.removeConsentValidationErrors($consentBox);
      return true;
    }
  },

  checkEmailAddress: function ($field) {
    if ($field.length === 0) {
      return true;
    }
    var val = $field.val();

    return (
      (val.trim() === '' &&
        $.inArray('emailAddress', this.options.requiredFields) === -1) ||
      EMAIL_REG_EXP.test(val)
    );
  },

  checkAttachmentSize: function ($field) {
    if ($field[0].files.length === 0) {
      return true;
    }

    var maxSize = this.options.maxMailSize,
      fileSize = $field[0].files[0].size;

    return fileSize < maxSize;
  },

  checkRequiredField: function ($field) {
    var filledOut = false;

    if ($.inArray($field.attr('name'), this.options.requiredFields) === -1) {
      return true;
    }

    if ($field.is('[type=checkbox]')) {
      filledOut =
        $field.is(':checked') ||
        $field.siblings('input[type=checkbox]').filter(':checked').length > 0;
    } else {
      filledOut = !!$field.val().trim();
    }
    return filledOut;
  },

  appendErrorMessage: function ($field, message) {
    $field.siblings('.sv-contact2-error-message-container').remove();
    $field.addClass('sv-contact2-warned');

    var $message = $('<label/>', {
      for: $field.attr('id'),
      role: 'alert',
      class: this.options.errorFont,
    }).html(
      '<img alt="" style="border:none; width:16px; height:16px; vertical-align:middle" src="' +
        this.options.errorImageSrc +
        '"> ' +
        message
    );

    $('<div class="sv-contact2-error-message-container"/>')
      .append($message)
      .appendTo($field.parent());
  },

  appendConsentErrorMessage: function ($consent) {
    $consent.siblings('.sv-form-consent-error-message-container').remove();
    $consent.addClass('sv-contact2-warned');

    var $message = $('<label/>', {
      for: $consent.attr('id'),
      role: 'alert',
      class: this.options.errorFont,
    }).html(
      '<img alt="" style="border:none; width:16px; height:16px; vertical-align:middle" src="' +
        this.options.errorImageSrc +
        '"> ' +
        this.options.errorMessages.consentMissing
    );

    $('<div class="sv-form-consent-error-message-container"/>')
      .append($message)
      .appendTo($consent.parent());
  },

  removeValidationErrors: function ($field) {
    var $container = $field ? $field.parent() : this.getDialogContainer();

    $container.find('.sv-contact2-error-message-container').remove();
  },

  removeConsentValidationErrors: function ($consent) {
    var $container = $consent ? $consent.parent() : this.getDialogContainer();

    $container.find('.sv-form-consent-error-message-container').remove();
  },

  handleSubmit: function () {
    var url = this.options.ajaxFormUrl,
      formData;

    if (!this.validateForm()) {
      return;
    }

    if (this.options.attachmentField) {
      this.getDialogContainer().find('form').trigger('submit');
    } else {
      formData = this.getDialogContainer().find('form').serialize();
      $.post(
        url,
        formData,
        $.proxy(function (aData) {
          this.closeDialog();
          this.showMessageDialog(aData);
        }, this)
      );
    }
  },

  closeDialog: function () {
    this.getDialogContainer().svDialog('close');
  },

  buildDialogUrl: function (contactId) {
    return (
      this.options.ajaxSendBaseUrl +
      '&contactUserId=' +
      contactId.substring(3).replace('_', '.')
    );
  },

  openDialog: function (link) {
    var $dialogContainer = this.getDialogContainer(),
      $consent = $dialogContainer.find('.sv-form-consent');

    if (this.dialogCreated) {
      $dialogContainer
        .svDialog('setURL', this.buildDialogUrl(link.id))
        .svDialog('open');
    } else {
      $dialogContainer.svDialog({
        open: true,
        url: this.buildDialogUrl(link.id),
        title: this.options.sendMessageTitle,
        modal: this.options.useModalDialog,
        buttons: [
          {
            text: this.options.submitButtonText,
            type: 'primary',
            callback: $.proxy(this.handleSubmit, this),
          },
          {
            text: this.options.cancelButtonText,
            type: 'link',
            callback: $.proxy(this.closeDialog, this),
          },
        ],
      });

      var that = this;
      $dialogContainer.on('change', 'input, textarea', function () {
        that.validateField($(this));
      });

      $dialogContainer.on('keydown', 'input[type=text]', function (e) {
        if (e.which === sv.KeyUtil.KEY.RETURN) {
          e.preventDefault();

          var $consentBox = $dialogContainer.find(
            'input[data-sv-form-consent-checkbox]'
          );
          if ($consentBox.length && !$consentBox.is(':checked')) {
            that.validateForm();
            return false;
          }
          that.handleSubmit();
        }
      });

      $dialogContainer.on(
        'change',
        'input[data-sv-form-consent-checkbox]',
        function () {
          that.validateConsent($(this));
        }
      );

      $dialogContainer.on(
        'keyup',
        'input[type=text].sv-contact2-warned, textarea.sv-contact2-warned',
        function () {
          that.validateField($(this));
        }
      );

      this.dialogCreated = true;
    }
  },
};

$('.sv-contact2-portlet').each(function () {
  var $this = $(this),
    data = sv.contact2 && sv.contact2[this.id.substring(4)];

  if (data) {
    // data will be undefined when someone has copied the link and opened navigated to it...
    data.$portlet = $this;
    var portlet = new ContactPortlet(data);
    $this.data('sv-contact2-portlet', portlet);
  }
});

import $ from '@sv/jquery';

const sizeFieldCache = {};

const countdownCharactersLeft = function ($target) {
  const value = $target.val(),
    text = value ? value.trim() : '',
    sizeField = sizeFieldCache[$target.attr('id')],
    size = sizeField.initialSize - text.length; //Text is trimmed server side

  if (size < 0) {
    sizeField.$el.addClass('sv-textfield-limit-exceeded');
  } else {
    sizeField.$el.removeClass('sv-textfield-limit-exceeded');
  }

  sizeField.$el.text(size);
};

$('.sv-form-textfield.sv-limit-characters').each(function () {
  const $this = $(this),
    sizeField = $this.parent().find('.sv-textfield-input-size'),
    id = $this.attr('id');

  sizeFieldCache[id] = {
    $el: sizeField,
    initialSize: sizeField.text(),
  };

  $this.on('keyup', function (e) {
    countdownCharactersLeft($(e.currentTarget));
  });

  if ($this.val().length) {
    countdownCharactersLeft($this);
  }
});

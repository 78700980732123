/**
 * Copyright (C) Sitevision 2002-2021, all rights reserved
 *
 * @author Karl Eklöf
 */

import $ from '@sv/jquery';
import sv from '@sv/core';
import { i18n, Ajax as ajax } from '@sv/util';
import { getEditApiUri } from '../util/portletUtil';

$('.sv-archive-portlet').on('click', '.sv-create-article-entry', function () {
  const $this = $(this);
  const archiveId = $this.data('archive-id');
  const templateId = $this.data('template-id');
  const container = $this.parent();
  const loadingInfo = $('<div>')
    .addClass('sv-loading-icon')
    .text(i18n.getText('portlet.archive.archive', 'creatingArticle'));

  container.empty().append(loadingInfo);

  ajax.doPost({
    url: getEditApiUri({
      objectId: archiveId,
      contextNodeId: archiveId,
      modelHandler: 'article',
    }),
    data: {
      template: templateId,
      portletType: 'archive',
    },
    success: function (result) {
      window.location.href = `/edit/${result.nodeId}`;
    },
  });

  return false;
});

if ($('.sv-archive-portlet').length) {
  sv.Events.trigger(sv.Events.types.updateLikables);
}

$('.sv-archive-portlet').on('click', '[data-fn-comment]', (e) => {
  const $target = $(e.currentTarget);

  import(/* webpackChunkName: "archive-plugins" */ './plugins').then(
    ({ initComment }) => {
      initComment($target);
    }
  );
  return false;
});

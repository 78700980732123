import $ from '@sv/jquery';

const setValueMissingMessage = ($el, valueMissing, message) => {
  $el
    .removeAttr('aria-invalid')
    .closest($el.data('sv-form-req-error-selector') || 'div')
    .removeClass('sv-has-error')
    .find('.sv-form-field-message')
    .remove();

  if (valueMissing) {
    $el[0].setCustomValidity(message);
    $el
      .attr('aria-invalid', 'true')
      .closest($el.data('sv-form-req-error-selector') || 'div')
      .addClass('sv-has-error')
      .append(`<div class="sv-form-field-message" style="margin-top: 1px">
                <span id="sv-field-message-${$el[0].id}"
                role="alert"
                style="vertical-align:top"
                class="normal">
                ${message}</span>
                <br></div>`);
  } else {
    $el[0].setCustomValidity('');
  }
};

const requiredListener = (e) => {
  let allValid = true;

  $(e.target)
    .closest('form')
    .find('[data-sv-form-req-invalid]')
    .each(function (i, el) {
      const $el = $(el);
      const validityState = el.validity;

      if (validityState.valueMissing) {
        allValid = false;
      }

      setValueMissingMessage(
        $el,
        validityState.valueMissing,
        $el.attr('data-sv-form-req-invalid')
      );
    });

  return allValid;
};

$('[data-sv-form-req-invalid]').each((i, el) => {
  const $el = $(el);
  const $form = $el.closest('form');
  $el.on('input', (e) => {
    const $el = $(e.target);
    setValueMissingMessage(
      $el,
      e.target.validity.valueMissing,
      $el.attr('data-sv-form-req-invalid')
    );
  });
  if (!$form.data('sv-form-req-invalid-listener')) {
    $form
      .on('submit', requiredListener)
      .on('click', '[type=submit]', requiredListener)
      .attr('novalidate', 'novalidate')
      .data('sv-form-req-invalid-listener', true);
  }
});

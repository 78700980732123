/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author micke
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import _ from '@sv/underscore';
import { ObjectUtil as objectUtil, Log as log } from '@sv/util';

var apiVersion = 1;

export const parseRequestParameters = function (parameterName) {
  var results = new RegExp('[\\?&]' + parameterName + '=([^&#]*)').exec(
    window.location.href
  );
  if (!results) {
    return null;
  }
  return results[1];
};

export const getModelObjectUri = function (
  objectId,
  modelHandler,
  operationObjectId
) {
  var url =
    '/modelobjectapi/' +
    apiVersion +
    '/' +
    sv.PageContext.pageId +
    '/' +
    objectId +
    '/' +
    modelHandler;
  if (operationObjectId) {
    url += '/' + operationObjectId;
  }

  return url;
};

export const getEditApiUri = function (options) {
  var modelHandler = options.modelHandler,
    objectId = options.objectId,
    operationObjectId = options.operationObjectId,
    contextNodeId = options.contextNodeId || sv.PageContext.pageId;

  if (typeof options === 'string') {
    modelHandler = options;
  }
  if (!objectId) {
    objectId = sv.PageContext.pageId;
  }
  var url =
    '/edit-api/' +
    apiVersion +
    '/' +
    contextNodeId +
    '/' +
    objectId +
    '/' +
    modelHandler; // handler methods: getAll, create
  if (operationObjectId) {
    // handler methods; get, update, delete
    url += '/' + operationObjectId;
  }
  return url;
};

// @deprecated use getPortletResourceUri
export const getPortletUri = function (portletId, actionName, json) {
  return `/${sv.PageContext.pageId}/${portletId}.${
    json ? 'json' : 'htm'
  }?state=${actionName}`;
};

export const getPortletResourceUri = function (portletId, actionName) {
  var identity = parseRequestParameters('identity');
  var group = parseRequestParameters('group');
  var tag = parseRequestParameters('tag');
  return (
    '/portletresource/' +
    sv.PageContext.pageId +
    '/' +
    portletId +
    '?state=' +
    actionName +
    (identity ? '&identity=' + identity : '') +
    (group ? '&group=' + group : '') +
    (tag ? '&tag=' + tag : '')
  );
};

export const reloadPortletsByType = function (typeSelector, query) {
  var portlets = $(typeSelector);

  $.each(portlets, function () {
    var portletDiv = $(this);
    var portletDivId = portletDiv.attr('id');
    var portletId = objectUtil.getObjectId(portletDivId);

    doGetWithFade(
      portletDiv,
      '/' + sv.PageContext.pageId + '/' + portletId + '.htm' + (query || '')
    );
  });
};

/**
 * Does a get-request to the url supplied.
 * When the get request returns the content of the element with supplied selector (E.g: .myClass or #myId etc.)
 * will fade out, and then be replaced with response from the get and the faded back.
 */
export const doGetWithFade = function (aSelector, anUrl, callback) {
  var selector;
  if (typeof aSelector === 'string') {
    selector = $(aSelector);
  } else {
    selector = aSelector;
  }

  $.get(anUrl, function (data) {
    selector.fadeOut('fast', function () {
      selector.html(data);
      selector.fadeIn();
      typeof callback === 'function' && callback.call(this, aSelector);
    });
  });
};

export const doGet = function (selector, anUrl) {
  $.get(anUrl, function (data) {
    $(selector).html(data);
  });
};

/**
 * A cache that prevents templates from being fetched from the DOM and compiled
 * multiple times.
 */
var templateCache = {};

/**
 * Gets and compiles a template
 * @param $portlet is a jQuery object of your portlet div
 * @param template is the name of the template without the 'sv-tmpl-' prefix
 */
export const getTemplate = function ($portlet, templateName) {
  var portletId = $portlet.attr('id'),
    $template;

  if (!portletId) {
    log.warn('portletUtil', 'Tried to get template from invalid element');
    return;
  }

  portletId = portletId.replace('.', '_');

  if (!templateCache[portletId + templateName]) {
    $template = $portlet.find('script.sv-tmpl-' + templateName);
    if ($template.length === 0) {
      log.warn(
        'portletUtil',
        'Could not find template with name ' +
          templateName +
          ' for portlet ' +
          portletId
      );
      return;
    }

    templateCache[portletId + templateName] = _.template($template.html());
  }

  return templateCache[portletId + templateName];
};

sv.PortletUtil = {
  getModelObjectUri: getModelObjectUri,
  getEditApiUri: getEditApiUri,
  getPortletUri: getPortletUri,
  getPortletResourceUri: getPortletResourceUri,
  reloadPortletsByType: reloadPortletsByType,
  doGetWithFade: doGetWithFade,
  doGet: doGet,
  getTemplate: getTemplate,
  parseRequestParameters: parseRequestParameters,
};

/**
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 *
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import { imgSlideshow } from '../util/slideShow/';

const playIcon =
  '<svg class="env-icon env-icon--xx-small imgSlideshowPlayIcon" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 92.2 122.88" viewBox="0 0 92.2 122.88"><path d="M92.2 60.97 0 122.88V0l92.2 60.97z" style="fill-rule:evenodd;clip-rule:evenodd"/></svg>';
const pauseIcon =
  '<svg class="env-icon env-icon--xx-small" width="800" height="800" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 4.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1V4.1c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1ZM2 4.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1V4.1c0-1.5-.64-2.1-2.23-2.1H4.23C2.64 2 2 2.6 2 4.1Z"/></svg>';

function createAndLoadSlideshowForId(portletId) {
  const $portlet = $(portletId);
  const id = '#' + $portlet.attr('id');
  const carousel = $portlet.find('.sv-carousel');
  const autoplay = carousel.data('fn-autoplay');
  const interval = carousel.data('fn-interval');
  const options = { autoplay: autoplay };

  if (autoplay && interval) {
    options.autoplayTimeout = interval;
  }

  imgSlideshow(id, options);

  if (autoplay) {
    const $pauseButton = $portlet.find('.sv-carousel_togglePlay_btn');
    $pauseButton.data('fnIsPlaying', true);

    const $toggleIcon = $pauseButton.find('.sv-carousel_toggle_icon');
    $toggleIcon.html(pauseIcon);

    const toggleSlideshow = (e) => {
      const $button = $(e.target).closest('button');
      const $carousel = $button.prev();
      const isPlaying = $button.data('fnIsPlaying');
      $button.data('fnIsPlaying', !isPlaying);

      $button
        .find('.sv-carousel_toggle_icon')
        .html(isPlaying ? playIcon : pauseIcon);

      $carousel.trigger(isPlaying ? 'stop.owl.autoplay' : 'play.owl.autoplay');
    };

    $pauseButton.on('click', toggleSlideshow);
  }
}

sv.createAndLoadSlideshowForId = createAndLoadSlideshowForId;
